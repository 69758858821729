import React from 'react'
import cn from 'classnames'
import { Link } from 'gatsby'
import * as st from '~/assets/styl/ListModules.module.styl'
import { useContext } from '~/hooks'

import api from '~/siteApi'

type Props = {
  pageContext: {
    id: number
    name: string
    description: string
    studies: Array<{
      name: string
      slug: string
      description: string
      video: string
      id: number
      image: string
      material: string
      rating: number
      // deletar mais tarde
      completed: number
      totalSteps: number
    }>
  }
}

export default function ListModules({
  pageContext: { name, description, studies },
}: Props) {
  const { profile, token, setProfile } = useContext()
  const isLogged = Boolean(profile)
  // const steps = [1, 2, 3, 4, 5]

  const favs = (profile?.studies_favorites || []).map((fav) => fav.id)

  function favorite(e, favorite) {
    e.preventDefault()
    const { studies_favorites } = profile
    api('patch', `studies/${favorite.id}/favorite`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        studies_favorites.push({ id: favorite.id })
        setProfile(profile)
      })
      .catch(([errors]) => {
        console.error(errors)
      })
  }

  function disfavor(e, disfavor) {
    e.preventDefault()
    const { studies_favorites } = profile
    api('patch', `studies/${disfavor.id}/disfavor`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        studies_favorites.splice(
          studies_favorites.findIndex(({ id }) => id === disfavor.id),
          1
        )
        setProfile(profile)
      })
      .catch(([errors]) => {
        console.error(errors)
      })
  }

  return (
    <div className={cn(st.categoriesContainer, 'page-width')}>
      <Link className={st.backToHome} to="/">
        <span className="icon-flag"></span>
        Home
      </Link>
      <section>
        <h1>{name}</h1>
        <div className={st.categoryDescription}>{description}</div>
        <div className={st.categoriesGrid}>
          {studies &&
            studies.map((study, id) => (
              <div key={id} className={st.categorySubject}>
                <Link to={'/modulo/' + study.slug}>
                  <div
                    className={st.categoryImage}
                    style={{ backgroundImage: `url(${study.image})` }}
                  >
                    {isLogged && (
                      <>
                        <div className={st.fav}>
                          {favs.includes(study.id) ? (
                            <span
                              onClick={(e) => disfavor(e, study)}
                              className="icon-filled-heart"
                            ></span>
                          ) : (
                            <span
                              onClick={(e) => favorite(e, study)}
                              className="icon-heart"
                            ></span>
                          )}
                        </div>
                        {study.rating > 0 && (
                          <div className={st.ratingBox}>
                            <span className="icon-rating"></span>
                            {study.rating.toString().replace('.', ',')}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <section className={st.subjectInfo}>
                    <h3>
                      <b>{study.name}</b>
                    </h3>
                    <p>{study.description}</p>
                  </section>
                  {isLogged && (
                    <div className={st.progressBar}>
                      <div className={st.progressBarWrapper}>
                        <div
                          className={st.progressBarFill}
                          style={{
                            width: `${
                              profile.progress.find(
                                ({ study: id }) => study.id === id
                              )?.progress || 0
                            }%`,
                          }}
                        ></div>
                      </div>
                      <p>
                        <span>
                          {profile.progress.find(
                            ({ study: id }) => study.id === id
                          )?.progress || 0}
                          %
                        </span>
                        concluído
                      </p>
                    </div>
                  )}
                  {/* <div className={st.steps}>
                  <ul
                    style={{
                      gridTemplateColumns: `repeat(${study.total},1fr)`,
                    }}
                  >
                    {steps.map((step, id) => (
                      <li
                        className={cn({
                          [st.stepCompleted]: id + 1 <= study.completed,
                        })}
                        key={id}
                      ></li>
                    ))}
                  </ul>
                  <div>
                    <span className={st.completed}>{study.completed}</span>
                    <span className={st.totalSteps}>
                      /<b>5</b>
                    </span>
                  </div>
                </div> */}
                </Link>
              </div>
            ))}
        </div>
      </section>
    </div>
  )
}
