import React from 'react'
import { Head } from '~/components'
import ListModules from '~/components/ListModules'
import Newsletter from '~/components/Newsletter'
import { useApi } from '~/siteApi'

const Curso = ({ pageContext, '*': slug }) => {
  const context = useApi(pageContext, 'courses/' + (pageContext.slug || slug))
  return (
    <>
      <Head />
      <ListModules pageContext={context} />
      <Newsletter />
    </>
  )
}

export default Curso
